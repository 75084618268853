@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  font-family: 'Rubik', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* .output-container {
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
} */
